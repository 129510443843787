<template>
  <div>
    <MultiSelect
      v-if="!!relationInfo && !!relatedCollection"
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="displayOptions"
      :virtualScrollerOptions="virtualScrollerOptions"
      :editable="false"
      :tabindex="props.field.info.meta.sortPosition"
      :aria-label="props.field.label"
      :class="['w-full']"
      inputClass="form-select"
      :modelValue="selectedItemIds"
      display="chip"
      :loading="isLoading"
      :placeholder="isLoading ? $t('items_is_loading') : ''"
      :disabled="isLoading || props.field.info.meta.isReadonly"
      @update:modelValue="onUpdateModelValue"
      v-on="dropdownEventListeners"
    >
      <template #header>
        <div class="p-dropdown-header">
          <div class="p-dropdown-filter-container">
            <input
              type="text"
              autocomplete="off"
              role="search"
              :value="search"
              :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
              v-on="searchEventListeners"
            />
            <span :class="['p-dropdown-filter-icon', 'pi pi-search']"></span>
          </div>
        </div>
      </template>

      <template #chip="slotProps">
        <div
          v-if="!!displayItemsMap[slotProps.value] && !!slotProps.value"
          class="flex items-center justify-between p-1"
        >
          <RenderTemplate
            :collectionName="relatedCollection.id"
            :item="displayItemsMap[slotProps.value]"
            :fieldInfo="props.field.info"
            :defaultTemplate="defaultDisplayTemplate"
          />
          <Button
            v-if="canNavigateToRelationalItem(relatedCollection)"
            size="small"
            variant="transparent"
            rounded
            @click="
              routeToItem(
                relatedCollection.id,
                displayItemsMap[slotProps.value].id,
                router,
              )
            "
          >
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </Button>
        </div>

        <template v-else>
          {{ slotProps.value }}
        </template>
      </template>

      <template #option="slotProps">
        <RenderTemplate
          v-if="!!displayItemsMap[slotProps.option.value]"
          :collectionName="relatedCollection.id"
          :item="displayItemsMap[slotProps.option.value]"
          :fieldInfo="props.field.info"
          :defaultTemplate="defaultDisplayTemplate"
        />

        <template v-else>
          {{ slotProps.option.label }}
        </template>
      </template>
    </MultiSelect>

    <PMessage v-else>
      {{ $t("field_relationship_not_setup") }}
    </PMessage>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface _VTI_TYPE_FieldInterface {
  readonly info: _VTI_TYPE_FieldInfoInterface;
  readonly data: any;
  readonly label: string;
  readonly isDirty: boolean;
  setData(data: any): this;
  setDirty(): this;
  setClean(): this;
}
interface FieldInterfaceEmits {
  (
    e: FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
    data: FieldDataUpdateEmitPayload,
  ): void;
}
interface FieldFormInterfaceProps {
  collection: _VTI_TYPE_CollectionInterface;
  item: _VTI_TYPE_ItemInterface;
  field: _VTI_TYPE_FieldInterface;
}
  import { toRef, computed, ref, shallowRef } from 'vue'
  import PMessage from 'primevue/message'
  import { useRouter } from 'vue-router'
  import { VirtualScrollerProps } from 'primevue/virtualscroller'
  import { useRelationO2M } from '~/api/relations/composables/useRelationO2M'
  import { defineEmitUpdateItemFieldDataPayload, FieldInterfaceEmitId } from '~/api/field-interfaces/emits'
  
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import { defineFieldManyData, FieldManyData, addRelatedPrimaryKeyToFields } from '~/entities/field'
  import { routeToItem } from '~/api/items/utils/navigators'
  import { canNavigateToRelationalItem } from '~/api/collections/utils/availability'
  import { RenderTemplate } from '~/entities/render-template'
  import MultiSelect from '~/components/MultiSelect/MultiSelect.vue'
  import { useRelationMultiple } from '~/api/relations/composables/useRelationMultiple'
  import { adjustFieldsForDisplays, getFieldsFromTemplate } from '~/api/field-displays/utils'
  import { useCollecitonsStore } from '~/stores/collections'
  import CollectionInterface from '~/api/collections/entities/CollectionInterface'
  import { OptionItem } from '~/components/DropdownSingleRelationRenderer/types'
  import Button from '~/shared/ui/Button'
  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();
  const router = useRouter();
  const collectionName = toRef(props.collection.id);
  const fieldName = toRef(props.field.info.name);
  const { relationInfo, relatedCollection } = useRelationO2M(collectionName, fieldName);
  const selectedItemIds = computed(() => {
    const currentIds =
      props.item.getFieldDataByExpression(fieldName.value)?.currentJunctionItemIds ?? [];
    return currentIds;
  });
  const onUpdateModelValue = (event: string[]) => {
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.info.name,
        updatedData: handleUpdateManyData(event),
      }),
    );
  };
  const collectionsStore = useCollecitonsStore();
  const defaultDisplayTemplate = computed<string>(() => {
    const fieldTemplate = props.field.info.meta.displayOptions?.template;
    if (!!fieldTemplate) return fieldTemplate;
    const collectionTemplate = relatedCollection.value?.meta?.displayTemplate;
    const primaryField = relatedCollection.value?.getPrimaryFieldInfo();
    return collectionTemplate || `{{ ${primaryField!.name} }}`;
  });
  const fields = computed(() => {
    if (!relationInfo.value) return [];
    const targetCollection: CollectionInterface = collectionsStore.getCollection(
      relationInfo.value.relatedCollection!,
    )!;
    if (!targetCollection) return [];
    const displayFields = adjustFieldsForDisplays(
      getFieldsFromTemplate(defaultDisplayTemplate.value),
      targetCollection,
    );
    return addRelatedPrimaryKeyToFields(targetCollection, displayFields);
  });
  const query = computed(() => {
    return {
      limit: -1,
      fields: fields.value || ["id"],
    };
  });
  const { fetchedItems, isLoading, updateRelationItems } = useRelationMultiple(
    computed(() => props.item.id),
    relationInfo,
    query,
  );
  const displayOptions = computed(() => fetchedItems.value.map(castItemToOption));
  const displayItemsMap = computed(() =>
    fetchedItems.value.reduce<Record<ItemInterface["id"], ItemInterface>>(
      (hashMap, item) => {
        if (item.id in hashMap) return hashMap;
        hashMap[item.id] = item;
        return hashMap;
      },
      {},
    ),
  );
  const dropdownEventListeners = {
    "before-show": async (event: unknown) => {
      await updateRelationItems();
    },
  };
  const search = ref<string>("");
  const searchEventListeners = {
    input: (event: InputEvent) => {
      const target = event.target as HTMLInputElement;
      search.value = target.value;
    },
  };
  const virtualScrollerOptions = shallowRef<VirtualScrollerProps>({
    lazy: true,
    showLoader: false,
    appendOnly: true,
    numToleratedItems: 0,
    loaderDisabled: true,
    orientation: "vertical",
    itemSize: 42,
  });
  function castItemToOption(item: ItemInterface): OptionItem {
    return {
      label: item.fields.find((field) => field.info.meta.isPrimaryKey)?.data ?? "",
      value: item.id,
    };
  }
  function handleUpdateManyData(data: string[]): FieldManyData {
    return defineFieldManyData({
      currentJunctionItemIds: data,
      newRelatedItemIds: [],
      removeJunctionItemIds: [],
      create: [],
    });
  }
</script>

<style scoped></style>

entities/field/lib/defines

